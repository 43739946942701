<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  ><path
    fill="currentColor"
    stroke="currentColor"
    stroke-linecap="round"
    stroke-width="1.5"
    d="M20 7H4m16 5H4m16 5H4"
  /></svg>
</template>
